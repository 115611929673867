import {
  array,
  assign,
  boolean,
  coerce,
  date,
  define,
  enums,
  type Infer,
  literal,
  nullable,
  number,
  object,
  optional,
  partial,
  string,
  Struct,
  tuple,
  union,
} from "superstruct";
import { formatToISO8601Date, isIso8601DateString, parseIso8601Date } from "@/utils/dates";
import { LOCALES_CONFIG, SPOKEN_LANGUAGES } from "@/i18n";
import type { Locale, SpokenLanguage } from "@/i18n/shared-types";

/**
 * Out of box `superstruct` has coercion for `undefined` but not for `null`.
 * This function adds coercion for `null` to `superstruct`'s `coerce` function.
 *
 * So if you have a `T | null | undefined` you can assign it a default value if it's `null` or `undefined`.
 */
function nullableCoerce<T, S, C>(
  struct: Struct<T, S>,
  condition: Struct<C, any>,
  coercer: (value: C | null) => T,
): Struct<T, S> {
  return coerce(struct, nullable(condition), (value): T | null =>
    value === null ? null : coercer(value),
  );
}

function resourceIdentifierC<T extends string>(dataType: T) {
  return object({
    id: string(),
    type: literal(dataType),
  });
}

export const IsoDateStringC = define<string>("IsoDateString", (value) =>
  typeof value !== "string" ? false : isIso8601DateString(value),
);

/**
 * Codec to parse an `ISO 8601` date string to a `Date` object.
 */
export const IsoDateC = coerce(date(), IsoDateStringC, (value) => parseIso8601Date(value));

/**
 * Special codec to parse dates like "1973-11-29" without timezone.
 */
export const NoTimeZoneDateC = coerce(date(), string(), (value) => {
  return new Date(`${value}T00:00:00`); // force local
});

/**
 * Represents a Date from an ISO string: 1973-11-29T23:00:00.000Z
 */
export const DateC = coerce(date(), string(), (value) => new Date(value));

/**
 * Codec to serialize a `Date` object to an `ISO 8601` date string.
 */
export const IsoDateToStringC = coerce(string(), date(), (value) => {
  return formatToISO8601Date(value);
});

/**
 * Codec to coerce a string to a number.
 */
export const NumberStrC = coerce(number(), string(), (value) => Number(value));

/**
 * Object representing an image, providing multiple sizes of the image.
 */
export const ImageSetC = object({
  aspect_ratio: string(),
  w1024: string(),
  w128: string(),
  w1600: string(),
  w2048: string(),
  w256: string(),
  w512: string(),
  w64: string(),
  w800: string(),
});

export type ImageSet = Infer<typeof ImageSetC>;

export const SpokenLanguageC = define<SpokenLanguage>("SpokenLanguage", (value) => {
  if (typeof value !== "string") {
    return false;
  }
  if (!Object.prototype.hasOwnProperty.call(SPOKEN_LANGUAGES, value)) {
    return false;
  }

  return true;
});

export const AppLanguageC = define<Locale>("AppLanguage", (value) => {
  if (typeof value !== "string") {
    return false;
  }
  if (!Object.prototype.hasOwnProperty.call(LOCALES_CONFIG, value)) {
    return false;
  }

  return true;
});

/**
 * Codec to coerce a 'xx_XX' to a 'xx-XX' locale strings.
 */
export const CoercedSpokenLanguageC = coerce(SpokenLanguageC, string(), (value) => {
  return value.replace(/_/g, "-");
});
/**
 * Codec to coerce a 'xx_XX' to a 'xx-XX' locale strings.
 */
export const CoercedAppLanguageC = coerce(AppLanguageC, string(), (value) => {
  return value.replace(/_/g, "-");
});

export const CareNavigatorC = object({
  id: string(),
  type: literal("call_expert"),
  attributes: object({
    body: nullable(string()),
    education: nullable(string()),
    experience: nullable(string()),
    fname: string(),
    image_set: nullable(ImageSetC),
    lname: string(),
    name: string(),
    role: string(),
    sign_languages: array(string()),
    spoken_languages: array(CoercedSpokenLanguageC),
    timezone: string(),
  }),
});

export const UpdateProfileCompanyPlanC = object({
  id: string(),
  type: literal("company_plan"),
  attributes: object({
    plan_name: string(),
  }),
});

export type UpdateProfileCompanyPlan = Infer<typeof UpdateProfileCompanyPlanC>;

export const SessionIncludedCompanyC = object({
  id: string(),
  type: literal("company"),
  attributes: object({
    name: string(),
    password_min_length: number(),
    care_plan_types: array(string()),
    company_plan_types: array(string()),
    languages: array(CoercedAppLanguageC),
    member_guide_url: nullable(string()),
  }),
});

export const SessionC = object({
  id: string(),
  type: literal("user"),
  attributes: object({
    partner: boolean(),
    fcm_token: string(),
    greeting_name: string(),
    preferred_name: optional(string()), // TODO: not optional
    preferred_language: CoercedAppLanguageC,
    notifications_enabled: boolean(),
    require_data_sharing_consent: boolean(),
    sendbird_credentials: object({
      access_token: string(),
      app_id: string(),
      channel_url: string(),
      user_id: string(),
    }),
    company_plan_type: nullable(string()),
    care_plan_type: nullable(string()),
    care_path: nullable(string()),
    pregnancy_week: nullable(number()),
    health_conditions: array(string()),
    ask_member_id: boolean(),

    // new fields STRK-2185_add-more-profile-data-to-session
    member_id: optional(nullable(string())),
    email: string(),
    unconfirmed_email: optional(nullable(string())),
    dob: NoTimeZoneDateC,
    zip: string(),
    phone: string(),
    personal_email: optional(nullable(string())),
    employer: optional(nullable(string())),
    sex: string(),
    gender: string(),
    gender_self_description: optional(nullable(string())), // TODO: not optional
    race: string(),
  }),
  relationships: object({
    care_navigator: CareNavigatorC,
    company: SessionIncludedCompanyC,
    company_plan: nullable(UpdateProfileCompanyPlanC),
  }),
});

export type Session = Infer<typeof SessionC>;

export const CallExpertC = object({
  id: string(),
  type: literal("call_expert"),
  attributes: object({
    body: nullable(string()),
    education: nullable(string()),
    experience: nullable(string()),
    fname: string(),
    image_set: nullable(ImageSetC),
    lname: string(),
    name: string(),
    role: string(),
    sign_languages: array(string()),
    spoken_languages: array(CoercedSpokenLanguageC),
    timezone: string(),
    timeslots: optional(array(string())),
    call_subject_name: optional(string()),
  }),
});

export type CallExpert = Infer<typeof CallExpertC>;

export const CallExpertsC = array(CallExpertC);

export type CallExperts = Infer<typeof CallExpertsC>;

export const CallExpertsMetaC = optional(
  object({
    alternate_care_partner_availability_only: boolean(),
  }),
);

export type CallExpertsMeta = Infer<typeof CallExpertsMetaC>;

export const CallC = object({
  id: string(),
  type: literal("call"),
  attributes: object({
    booked_at: DateC,
    ends_at: DateC,
    call_subject_type: string(),
    call_subject_type_name: string(),
    token: string(),
    note: nullable(string()),
    time_to_join: number(),
    time_to_stop_edit: number(),
  }),
  relationships: object({
    call_expert: CallExpertC,
  }),
});

export type Call = Infer<typeof CallC>;

export const CallsC = array(CallC);

export type Calls = Infer<typeof CallsC>;

export const ResetPasswordTokenHolderC = object({
  id: string(),
  type: literal("reset_password_token_holder"),
  attributes: object({
    email: string(),
    password_min_length: number(),
  }),
});

export const SignUpTokenHolderIncludedCompanyC = object({
  id: string(),
  type: literal("company"),
  attributes: object({
    name: string(),
    password_min_length: number(),
    // partner_invitation_enabled: boolean(),
    country_name: string(),
    country_code: string(),
    care_plan_types: array(string()),
    care_team_email: nullable(string()),
  }),
});

export const SignUpTokenTokenHolderC = object({
  id: string(),
  type: literal("registration_token_holder"),
  attributes: object({
    email: string(),
    require_employee_id: boolean(),
  }),
  relationships: object({
    allowed_companies: array(SignUpTokenHolderIncludedCompanyC),
  }),
});

export const PolicyC = object({
  id: string(),
  type: literal("company_policy"),
  attributes: object({
    body: string(),
    name: string(),
  }),
});

export type Policy = Infer<typeof PolicyC>;

export const PoliciesC = array(PolicyC);

export type Policies = Infer<typeof PoliciesC>;

export const ProgramC = object({
  id: string(),
  type: literal("program"),
  attributes: object({
    name: string(),
    body: optional(string()),
    reimbursable: optional(boolean()),
    remaining_reimbursable_coverage: optional(number()),
    total_reimbursable_coverage: optional(number()),
    flat_max_reimbursement: optional(boolean()),
  }),
  relationships: optional(
    object({
      program_category: optional(
        object({
          id: string(),
          attributes: object({
            name: string(),
          }),
        }),
      ),
    }),
  ),
});

export type Program = Infer<typeof ProgramC>;

export const ProgramsC = array(ProgramC);

export type Programs = Infer<typeof ProgramsC>;

export const ProviderC = object({
  id: string(),
  type: literal("provider"),
  attributes: object({
    name: string(),
  }),
  relationships: optional(
    object({
      program_category: optional(
        object({
          id: string(),
        }),
      ),
    }),
  ),
});

export type Provider = Infer<typeof ProviderC>;

export const ProgramCategoryC = object({
  id: string(),
  type: literal("program_category"),
  attributes: object({
    name: string(),
  }),
  relationships: optional(
    object({
      programs: ProgramsC,
    }),
  ),
});

export type ProgramCategory = Infer<typeof ProgramCategoryC>;

export const ProgramCategoriesC = array(ProgramCategoryC);

export type ProgramCategories = Infer<typeof ProgramCategoriesC>;

export const CallSubjectC = object({
  id: string(),
  type: literal("call_subject"),
  attributes: object({
    call_subject_type: string(),
    name: string(),
    talk_to_expert_text: string(),
  }),
  relationships: object({
    call_experts: array(CallExpertC),
  }),
});

export type CallSubject = Infer<typeof CallSubjectC>;

export const DoctorC = object({
  id: string(),
  type: literal("doctor"),
  attributes: object({
    name: string(),
    certification: string(),
    community: string(),
    ethnicity: string(),
    biography: string(),
    languages_spoken: array(CoercedSpokenLanguageC),
    image_set: nullable(ImageSetC),
  }),
});

export const LocationC = object({
  id: string(),
  type: literal("location"),
  attributes: object({
    address: string(),
    phone: object({
      national_format: nullable(string()),
      international_format: nullable(string()),
    }),
    latitude: number(),
    longitude: number(),
  }),
});

export const ClinicC = object({
  id: string(),
  type: literal("clinic"),
  attributes: object({
    practice_uid: nullable(string()),
    url: nullable(string()),
    title: string(),
    body: string(),
    distance_for_closest_location: nullable(number()),
  }),
  relationships: object({
    program: ProgramC,
    doctors: array(DoctorC),
    closest_location: nullable(LocationC),
  }),
});

export type Clinic = Infer<typeof ClinicC>;

export const ClinicsC = array(ClinicC);

export type Clinics = Infer<typeof ClinicsC>;

export const CallCategoriesC = array(
  object({
    id: string(),
    type: literal("call_category"),
    attributes: object({
      name: string(),
    }),
  }),
);

export type CallCategories = Infer<typeof CallCategoriesC>;

export const CallSubjectsC = array(
  object({
    id: string(),
    type: literal("call_subject"),
    attributes: object({
      call_subject_type: string(),
      name: string(),
      talk_to_expert_text: string(),
      call_category_name: string(),
    }),
    relationships: object({
      call_experts: array(CallExpertC),
    }),
  }),
);

export type CallSubjects = Infer<typeof CallSubjectsC>;

export const TopicC = object({
  id: string(),
  type: literal("post_category"),
  attributes: object({
    name: string(),
    slug: string(),
  }),
});

export type Topic = Infer<typeof TopicC>;

export const TopicsC = array(TopicC);

export const PostCategoryC = object({
  id: string(),
  type: literal("post_category"),
  attributes: object({
    name: string(),
    slug: string(),
  }),
});

export const PostBlockC = object({
  id: string(),
  type: literal("post_block"),
  attributes: object({
    image_set: optional(ImageSetC),
    body: optional(string()),
    embed: optional(string()),
    media: enums(["body", "embed", "photo"]),
  }),
});

export const PostC = object({
  id: string(),
  type: literal("post"),
  attributes: object({
    image_set: ImageSetC,
    intro: string(),
    name: string(),
    // show_cover: boolean(),
    slug: string(),
  }),
  relationships: object({
    post_category: PostCategoryC,
  }),
});

export type Post = Infer<typeof PostC>;

export const PostsC = array(PostC);

export type Posts = Infer<typeof PostsC>;

export const ArticleC = object({
  id: string(),
  type: literal("post"),
  attributes: object({
    image_set: ImageSetC,
    intro: string(),
    name: string(),
    slug: string(),
    published_at: IsoDateC,
    min_to_read: number(),
  }),
  relationships: object({
    post_category: PostCategoryC,
    post_blocks: array(PostBlockC),
  }),
});

export type Article = Infer<typeof ArticleC>;

const CareTaskIncludedC = object({
  id: string(),
  type: literal("care_task_element"),
  attributes: object({
    widget_type: enums([
      "call_subject_widget",
      "program_widget",
      "provider_widget",
      "post_widget",
      "markdown_widget",
      "policy_widget",
    ]),
    body: optional(string()), // for markdown_widget type (not a component, renders as-is)
  }),
  relationships: partial({
    policy: nullable(PolicyC),
    post: nullable(PostC),
    call_subject: nullable(CallSubjectC), // null if widget call_subject is not in the list of user's company call subjects
    program: nullable(ProgramC), // null if widget program is not in the list of user's company programs
    provider: nullable(ProviderC), // null if widget program is not in the list of user's company programs
  }),
});

export const CareTaskC = object({
  id: string(),
  type: literal("care_task"),
  attributes: object({
    title: string(),
    completed: boolean(),
    image_set: nullable(ImageSetC),
  }),
  relationships: object({
    care_task_elements: array(CareTaskIncludedC),
  }),
});

export type CareTask = Infer<typeof CareTaskC>;

export const CareTasksC = array(
  object({
    id: string(),
    type: literal("care_task"),
    attributes: object({
      title: string(),
      completed: boolean(),
    }),
  }),
);

export type CareTasks = Infer<typeof CareTasksC>;

export const CompanyC = object({
  id: string(),
  type: literal("company"),
  attributes: object({
    uid: string(),
    name: string(),
  }),
});

export const CompanyPlanTypeC = enums(["single", "family", "plus_one", "unknown"]);

export const UserPlanC = object({
  id: string(),
  type: literal("company_plan"),
  attributes: object({
    archived: boolean(),
    plan_name: string(),
    plan_type: CompanyPlanTypeC,
    oop_max: number(),
    deductible: number(),
    no_deductible_rx: boolean(),
    copay: nullable(number()),
    copay_rx: nullable(number()),
    coinsurance: nullable(number()),
    coinsurance_rx: nullable(number()),
  }),
});

export type UserPlan = Infer<typeof UserPlanC>;

export const BillStateC = enums(["requested", "initiated", "paid", "manually_collected", "failed"]);

export type BillState = Infer<typeof BillStateC>;

export const BillC = object({
  id: string(),
  type: literal("bill"),
  attributes: object({
    state: BillStateC,
    service_name: string(),
    service_date: IsoDateC,
    provider_name: string(),
    sequential_id: string(),
    due_soon: boolean(),
    overdue: boolean(),
    requested_at: DateC,
    start_processing_at: nullable(DateC),
    processed_at: nullable(DateC),
    payment_soon_due_date: DateC,
    payment_due_date: DateC,
    provider_category_template: enums(["pharmacy", "default"]),
    total_amount: number(),
    covered_amount: number(),
    amount: number(),
    unread_at: nullable(DateC),
    authorization_id: string(),
    authorization_uid: string(),
    oop_span: nullable(tuple([number(), number()])),
    plaid_link_token: optional(nullable(string())),
  }),
  relationships: object({
    company_plan: nullable(UserPlanC),
  }),
});

export type Bill = Infer<typeof BillC>;

/**
 * The entity returned by `/bills/:billId` endpoint has a little different set of attributes.
 */
export const PlaidBillC = object({
  id: string(),
  type: literal("bill"),

  attributes: object({
    state: BillStateC,
    service_name: string(),
    service_date: IsoDateC,
    provider_name: string(),
    sequential_id: string(),
    provider_category_template: enums(["pharmacy", "default"]),
    total_amount: number(),
    covered_amount: number(),
    amount: number(),
    plaid_link_token: string(),
  }),
});

export type PlaidBill = Infer<typeof PlaidBillC>;

export const NotificationServiceStateC = enums(["approved", "rejected", "requested", "canceled"]);

export type NotificationServiceState = Infer<typeof NotificationServiceStateC>;

export const NotificationServiceC = object({
  id: string(),
  type: literal("authorization_service"),
  attributes: object({
    service_name: string(),
    service_code: string(),
    amount: number(),
    state: NotificationServiceStateC,
  }),
});

export type NotificationService = Infer<typeof NotificationServiceC>;

export const NotificationC = object({
  id: string(),
  type: literal("notification"),
  attributes: object({
    // can be null for some users (like Everside). Everside has no `bundle_equivalent` and `max_rx_coverage`.
    // We should hide `The service if provided in full will deduct...` block in this case.
    // old ux had `bundle_equivalent` but as we are going to support multiple services in the new UX,
    // we move to the `total_bundle_equivalent` field.
    total_bundle_equivalent: nullable(number()),
    total_rate: number(),
    // it was `expired_at` in the old UX.
    expires_at: nullable(DateC),
    max_rx_coverage: optional(nullable(string())),
    oop_span: nullable(tuple([number(), number()])),
    // it was `practice_name` in the old UX.
    provider_name: string(),
    // it was `practice_template` in the old UX.
    provider_category_template: enums(["pharmacy", "default"]),
    service_date: IsoDateC,
    title: string(),
    uid: string(),
    unread_at: nullable(DateC),
  }),
});

export const NotificationEventStatusC = enums([
  "requested",
  "approved",
  "rejected",
  "canceled",
  "updated",
]);

export type NotificationEventStatus = Infer<typeof NotificationEventStatusC>;

export const NotificationEventC = object({
  id: string(),
  type: literal("notification_event"),
  attributes: object({
    name: NotificationEventStatusC,
    created_at: DateC,
    author: string(),
  }),
});
export type NotificationEvent = Infer<typeof NotificationEventC>;

export const NotificationWithRelationshipsC = assign(
  NotificationC,
  object({
    relationships: object({
      services: array(NotificationServiceC),
      events: array(NotificationEventC),
      company_plan: nullable(UserPlanC),
    }),
  }),
);

export type NotificationWithRelationships = Infer<typeof NotificationWithRelationshipsC>;

export const ReimbursementServiceStateC = enums(["approved", "rejected", "requested", "canceled"]);

export type ReimbursementServiceState = Infer<typeof ReimbursementServiceStateC>;

export const ReimbursementC = object({
  id: string(),
  type: literal("reimbursement"),
  attributes: object({
    created_at: DateC,
    program_name: string(),
    provider_name: string(),
    unread_at: nullable(DateC),
    state: ReimbursementServiceStateC,
    total_approved_amount: number(),
    total_requested_amount: number(),
    uid: string(),
  }),
});

export type Reimbursement = Infer<typeof ReimbursementC>;

export const ReimbursementEventStatusC = enums(["requested", "approved", "rejected", "canceled"]);

export type ReimbursementEventStatus = Infer<typeof ReimbursementEventStatusC>;

export const ReimbursementServiceC = object({
  id: string(),
  type: literal("reimbursement_service"),
  attributes: object({
    approved_amount: number(),
    requested_amount: number(),
    service_name: string(),
    state: ReimbursementServiceStateC,
    rejection_reason: optional(string()),

    // date: IsoDateC,
  }),
});

export type ReimbursementService = Infer<typeof ReimbursementServiceC>;

export const ReimbursementEventC = object({
  id: string(),
  type: literal("reimbursement_event"),
  attributes: object({
    created_at: DateC,
    name: ReimbursementEventStatusC,
    user_event: boolean(),
  }),
});

export type ReimbursementEvent = Infer<typeof ReimbursementEventC>;

export const ReimbursementWithRelationshipsC = assign(
  ReimbursementC,
  object({
    relationships: object({
      services: array(ReimbursementServiceC),
      events: array(ReimbursementEventC),
    }),
  }),
);

export type ReimbursementWithRelationships = Infer<typeof ReimbursementWithRelationshipsC>;

export const NotificationAndReimbursementWithRelationshipsC = union([
  NotificationWithRelationshipsC,
  ReimbursementWithRelationshipsC,
]);

export type NotificationAndReimbursementWithRelationships = Infer<
  typeof NotificationAndReimbursementWithRelationshipsC
>;

export const HealthPartnerC = nullable(
  object({
    id: string(),
    type: literal("partner"),
    attributes: object({
      email: string(),
      invitation_accepted: boolean(),
    }),
  }),
);

export type HealthPartner = Infer<typeof HealthPartnerC>;

export const UnreadMessagesCountC = coerce(number(), nullable(number()), (value) => {
  return value === null ? 0 : value;
});

export type UnreadMessagesCount = Infer<typeof UnreadMessagesCountC>;
